@import '../../../../scss/variables';

.slick-slider {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
}

.notes-preview-text {
    margin-top: 0.8rem;
    margin-bottom: 2.2rem;
    color: var(--text-color);
}
