html {
    font-family: $inter;
    font-size: 62.5%;
}

body {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6{
    margin-top: 0;
    margin-bottom: .8rem;
    font-weight: bold;
    color: var(--heading-color);
}

h1 {
    font-size: 2rem;
    line-height: 2.4rem;
}

h2 {
    font-size: 1.6rem;
    line-height: 1.9rem;

}

h3 {
    font-size: 1.4rem;
    line-height: 1.7rem;
}

p,
ol,
ul{
    margin-top: 0;
    margin-bottom: 1.6rem;
}