@import "./../../../scss/variables.scss";

.footer{
    margin-top: 2rem;
    padding-top: 1.6rem;
    border-top: .1rem solid var(--form-element-border-color);

    ul{
        display: grid;
        margin: 0;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto;
    }
}