@import '../../../scss/variables';

.button{
    display: inline-block;
    padding: 1rem;
    color: var(--text-color);
    background: none;
    border: solid 0.1rem var(--form-element-border-color);
    border-radius: 0.8rem;
    vertical-align: top;
    cursor: pointer;
    transition: .2s all;

    &.small{
        padding: .4rem .8rem;
    }

    &.primary {
        background: var(--primary-color);
        color: var(--primary-text-color);
        border-color: var(--primary-color);
    }

    &.link,
    &.dropdown-item{
        padding: 0;
        border: none;
        color: inherit;
        border-radius: inherit;

        &:focus{
            outline: inherit;
        }

        &:disabled{
            background: none;
            color: var(--disabled-text-color);
        }
    }

    &.solid{
        padding-top: .7rem;
        padding-bottom: .7rem;
        text-align: center;
        color: $white;
        background: $solid-purple;
        border-color: darken($solid-purple, 5%);

        &:focus{
            outline: .1rem solid $solid-purple;
        }

        img,
        span{
            display: inline-block;
            vertical-align: middle;
        }

        img{
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
        }
    }

    &:focus{
        outline: .1rem solid var(--primary-color);
    }

    &:disabled{
        background: var(--disabled-background-color);
        border-color: var(--disabled-border-color);
        color: var(--disabled-text-color);
        cursor: not-allowed;
    }
}