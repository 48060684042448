@import '../../../../scss/variables';

.container{
    margin-bottom: 4rem;
    padding: 1.2rem;
    border: 0.1rem solid var(--form-element-border-color);
    border-radius: 0.8rem;
    color: var(--text-color);
}

.actions-container{
    display: flex;
    margin-bottom: 1.6rem;
    justify-content: flex-end;
}

.actions{
    display: none;
    margin: 0;
}

.note-list{
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: disc;

    & > li{
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto;
        align-items: center;
        column-gap: .8rem;

        & + li{
            margin-top: .8rem;
            padding-top: .8rem;
            border-top: .1rem solid var(--form-element-border-color);
        }
    }
}

@media screen and (min-width: 768px) {
    .dropdown{
        display: none !important;
    }

    .actions{
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto;
        align-items: center;
        column-gap: .8rem;
    }

    .note-list{
        & > li{
            & + li{
                padding-top: 0;
                border-top: none;
            }
        }
    }
}