.wrapper{
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    min-height: 100vh;
    width: 100vw;
    max-width: 70rem;
    margin: 0 auto;
    padding: 2rem;
    overflow: hidden;
}