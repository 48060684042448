@import '../../../scss/variables';

.dropdown-container{
    position: relative;
    z-index: 1;
    display: inline-block;
}

.dropdown-button{

}

.dropdown-list{
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 2;
    display: inline-block;
    margin: .8rem 0 0 0;
    padding: .8rem;
    border-radius: 0.8rem;
    border: solid 0.1rem var(--form-element-border-color);
    background: var(--background-color);
    opacity: 0;
    pointer-events: none;
    transition: .2s all;

    ul{
        margin: 0;

        li{
            text-wrap: nowrap;
            & + li{
                margin-top: .8rem;
            }
        }
    }

    &.show{
        margin-top: .4rem;
        opacity: 1;
        pointer-events: auto;
    }
}