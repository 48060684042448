@import "./../../../scss/variables.scss";

.header{
    display: grid;
    margin-bottom: 2rem;
    grid-template-columns: auto 1fr;
    justify-items: end;
    column-gap: 1rem;

    .header-right{
        display: grid;
        grid-template-columns: auto 1fr auto;
        justify-items: end;
        column-gap: 1rem;
    }

    h2{
        display: flex;
        margin-bottom: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    &.header-note,
    &.header-login{
        grid-template-columns: auto 1fr auto;
    }

    &.logged-in{
        grid-template-columns: 1fr auto auto;
    }

    &.not-logged-in{
        grid-template-columns: 1fr auto;
    }
}

.profile-info{
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1rem;
    align-items: center;
    color: var(--placeholder-color);
    text-align: right;
}

.profile-image{
    display: block;
    max-width: 3.4rem;
    height: auto;
    width: auto;
    border: solid 0.1rem var(--form-element-border-color);
    border-radius: .8rem;
}

.logout-button-wrapper{
    display: inline-block;
}