@import './variables';

:root{
    --background-color: #{$white};
    --heading-color: #{$neutral-700};
    --text-color: #{$neutral-500};
    --form-element-border-color: #{$neutral-100};
    --form-element-input-group-text-background:#{lighten($neutral-100, 5%)};
    --placeholder-color: #{$neutral-400};
    --primary-color: #{$brand-blue};
    --primary-text-color: #{$white};
    --link-color: #{$brand-blue};
    --disabled-background-color: #{$neutral-100};
    --disabled-border-color: #{$neutral-200};
    --disabled-text-color: #{$neutral-400};
}

[data-theme="light"] {
    --background-color: #{$white};
    --heading-color: #{$neutral-700};
    --text-color: #{$neutral-500};
    --form-element-border-color: #{$neutral-100};
    --form-element-input-group-text-background:#{lighten($neutral-100, 5%)};
    --placeholder-color: #{$neutral-400};
    --primary-color: #{$brand-blue};
    --primary-text-color: #{$white};
    --link-color: #{$brand-blue};
    --disabled-background-color: #{$neutral-100};
    --disabled-border-color: #{$neutral-200};
    --disabled-text-color: #{$neutral-400};
}

[data-theme="dark"] {
    --background-color: #{$neutral-800};
    --heading-color: #{$white};
    --text-color: #{$white};
    --form-element-border-color: #{$neutral-400};
    --form-element-input-group-text-background:#{$neutral-800};
    --placeholder-color: #{$neutral-100};
    --primary-color: #{$brand-blue};
    --primary-text-color: #{$white};
    --link-color: #{$brand-blue};
    --disabled-background-color: #{$neutral-100};
    --disabled-border-color: #{$neutral-200};
    --disabled-text-color: #{$neutral-400};
}