@import "../../../../scss/variables";

.task-insert-listing{
    margin-bottom: 0.8rem;
    padding: 1.2rem;
    border: 0.1rem solid var(--form-element-border-color);
    border-radius: 0.8rem;

    ul{
        margin-bottom: 0;
    }

    li,
    .task-field-wrapper{
        display: grid;
        grid-template-rows: auto;
        column-gap: .4rem;
        align-items: center;
    }

    li{
        grid-template-columns: 1fr auto;
        padding-left: 0;
    }

    .task-field-wrapper{
        grid-template-columns: auto 1fr;
    }

    input{
        &[type="text"]{
            display: block;
            margin: 0;
            padding: .4rem;
            line-height: 2rem;
            border: 0;
            background: none;
        }
    }

    .task-is-done{
        text-decoration: line-through;
        color: var(--text-color);
    }
}

.button-add-task {
    margin-bottom: 0.8rem;
}

.button-remove-task{
    width: 2.8rem;
    margin: 0;
    padding: 0;
    border: none;
    color: var(--text-color);
}

.actions{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    row-gap: .8rem;

    :global{
        button{
            display: block;
            width: 100%;
        }
    }
}

.input-group{
    display: grid;
    grid-template-columns: auto 1fr;

    input{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.input-group-text{
    display: block;
    width: 100%;
    margin-bottom: 0.8rem;
    padding: 1.2rem;
    color: var(--text-color);
    border: 0.1rem solid var(--form-element-border-color);
    border-right: none;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    background: var(--form-element-input-group-text-background);
}