@import "./../../../scss/variables.scss";

.grid-container {
    position: relative;
    z-index: 1;
    min-width: 0;
}

.title-container{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    column-gap: 3rem;
}

.loading-container{
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    background: var(--background-color);
    opacity: 0;
    pointer-events: none;

    &.is-loading{
        opacity: 1;
        pointer-events: auto;
    }
}

.subtitle {
    display: block;
    margin-bottom: 0.8rem;
    color: var(--placeholder-color);
}

.category-listing {
    display: block;
    margin: 3.2rem 0 .8rem 0;
    color: var(--placeholder-color);

    & > li {
        display: inline-block;
        vertical-align: top;

        & + li {
            margin-left: 1.6rem;
        }

        &.active {
            color: var(--primary-color);
            border-bottom: 0.1rem solid var(--primary-color);
        }
    }
}



.page-slide{
    position: absolute;
    top: 0;
    left: 100%;
    width: 100vw;
    background: var(--background-color);
    transition: left .1s;

    &.show{
        left: 0;
    }

    header{
        display: grid;
        margin-bottom: 2rem;
        grid-template-columns: auto 1fr auto;
        column-gap: 1rem;
        justify-content: space-between;
        align-items: center;

        h2{
            display: inline-block;
            margin-bottom: 0;
            line-height: 1;
            text-align: center;
        }
    }
}