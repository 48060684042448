@import '../../../scss/variables';

.checkbox{
    display: inline-block;
    vertical-align: top;

    input[type="checkbox"] {
        display: none;
        width: auto;
        margin-bottom: 0;

        & + span{
            position: relative;
            display: inline-block;
            height: 1.6rem;
            width: 1.6rem;
            margin: 0;
            vertical-align: top;
            border: .2rem solid var(--form-element-border-color);
            border-radius: .2rem;
            content: '';

            &.has-label{
                margin-right: .5rem;
            }
        }

        &:checked{
            & + span{
                &:before{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -.5rem;
                    margin-top: -.6em;
                    font-family: $font-awesome;
                    font-weight: 900;
                    font-size: 1.2rem;
                    color: var(--text-color);
                    content: '\f00c';
                }
            }
        }
    }
}