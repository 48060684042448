.input{
    display: block;
    width: 100%;
    margin-bottom: 0.8rem;
    padding: 1.2rem;
    color: var(--text-color);
    border: 0.1rem solid var(--form-element-border-color);
    border-radius: 0.8rem;
    background: none;

    &::placeholder{
        color: var(--placeholder-color);
        font-style: italic;
    }

    &:focus{
        outline: .1rem solid var(--primary-color);
    }

    &[type="radio"] {
        display: inline-block;
        width: auto;
        margin-right: .5rem;
        vertical-align: top;
    }

    &:disabled{
        background: var(--disabled-background-color);
        border-color: var(--disabled-border-color);
        color: var(--disabled-text-color);
        cursor: not-allowed;
    }
}