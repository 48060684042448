@import "../../../../scss/variables";

.note-preview {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr auto;
    column-gap: 2rem;
    align-items: center;
}

.title{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    font-weight: bold;
}

.date {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    color: var(--placeholder-color);
}

.tasks{
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
    color: var(--placeholder-color);

    i {
        color: var(--primary-color);
    }
}

.tag{
    position: relative;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    font-size: 2rem;
    text-align: right;

    .number-of-tags {
        position: absolute;
        top: 0.5rem;
        right: 0.6rem;
        font-size: .8rem;
        line-height: 1rem;
        font-weight: bold;
        color: var(--background-color);
    }
}


@media screen and (min-width: 768px) {
    .note-preview {
        grid-template-columns: 1fr auto auto auto;
    }

    .title {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .date {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 3;
    }

    .tasks {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 4;
    }

    .tag {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 4;
        grid-column-end: 5;
    }
}