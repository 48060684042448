$white: rgb(255,255,255);

$neutral-50: rgb(247, 248, 249);
$neutral-100: rgb(231, 234, 238);
$neutral-200: rgb(208, 213, 221);
$neutral-400: rgb(160, 171, 187);
$neutral-500: rgb(100, 116, 139);
$neutral-700: rgb(50, 58, 70);
$neutral-800: rgb(25, 29, 35);

$brand-blue: rgb(15, 130, 247);
$solid-purple: rgb(124,77,255); //#7C4DFF

$font-awesome: "Font Awesome 6 Free";
$font-awesome-brands: "Font Awesome 6 Brands";
$inter: "Inter", sans-serif;
