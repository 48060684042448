.textarea{
    display: block;
    width: 100%;
    margin-bottom: 0.8rem;
    padding: 1.2rem;
    color: var(--text-color);
    border: 0.1rem solid var(--form-element-border-color);
    background: none;
    border-radius: 0.8rem;

    &::placeholder{
        color: var(--placeholder-color);
    }

    &:focus{
        outline: .1rem solid var(--primary-color);
    }
}