* {
    box-sizing: border-box;
}

body{
    overflow-x: hidden;
    background: var(--background-color);
}

.fa-left {
    margin-right: 0.4rem;
}


.fa-right{
    margin-left: 0.4rem;
}

a{
    &:not(.btn) {
        &,
        &:visited{
            color: var(--link-color);
        }
    }
}