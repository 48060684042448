@import '../../../../scss/variables';

.note-placeholder-container {
    min-height: 10rem;
    margin-bottom: 0.8rem;
    padding: 1.2rem;
    border: 0.1rem solid var(--form-element-border-color);
    border-radius: 0.8rem;
    color: var(--placeholder-color);
}

.dummy-save-button{
    display: block;
    width: 100%;
}

.disabled{
    background: var(--disabled-background-color);
    border-color: var(--disabled-border-color);
    color: var(--disabled-text-color);
    cursor: not-allowed;
}