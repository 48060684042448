@import "../../../scss/variables.scss";

.identity-provider-wrapper{
    display: inline-block;
    width: 100%;
    max-width: 50rem;
    margin: 2rem 0;
    padding: 2rem;
    border: solid 0.1rem var(--form-element-border-color);
    border-radius: 0.8rem;

    ul{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}