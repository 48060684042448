@import "../../../../scss/variables";

.note-preview {
    display: block;
    //width: 20rem;
    margin: 0 .8rem;
    padding: 1.8rem;
    border: 0.1rem solid var(--form-element-border-color);
    border-radius: 0.8rem;
    cursor: pointer;

    p {
        margin-bottom: 1.6rem;
    }
}

.note-meta-data-listing {
    display: flex;
    justify-content: space-between;

    & > li {
        &:first-child {
            color: var(--text-color);

            i {
                color: var(--primary-color);
            }
        }

        &:nth-child(2) {
            position: relative;

            i {
                font-size: 2rem;
            }
        }
    }
}

.number-of-tags {
    position: absolute;
    top: 0.7rem;
    left: 0.7rem;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: bold;
    color: var(--background-color);
}

.date {
    font-size: 0.8rem;
    line-height: 1rem;
    color: var(--placeholder-color);
}